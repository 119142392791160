import React from "react";
import ProductList from "./plp"; // Product listing component
import AccordionFilter from "../components/filter"; // Accordion filter component

const ProductListingPage = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Filter Sidebar */}
        <div className="lg:w-1/3 lg:sticky lg:top-0 lg:h-screen overflow-y-auto lg:bg-gray-100 md:bg-gray-100 ">
          <AccordionFilter />
        </div>

        {/* Product List Section */}
        <div className="lg:w-2/3">
          <ProductList />
        </div>
      </div>
    </div>
  );
};

export default ProductListingPage;
