import React, { Suspense, useEffect } from "react";
import RoutesOmApp from "./router";
import { LinearProgress } from "@mui/material";
import ShoppingCartState from "../src/components/shoppingCart/ShoppingCartState";
import { NavbarProvider } from "../src/components/navbar/NavbarContext";
import ErrorBoundary from "./components/logs/ErrorBoundary";
function App() {
  return (
    <ErrorBoundary>
      <NavbarProvider>
        <ShoppingCartState>
          <Suspense fallback={<LinearProgress />}>
            <RoutesOmApp />
          </Suspense>
        </ShoppingCartState>
      </NavbarProvider>
    </ErrorBoundary>
  );
}

export default App;
