import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";

const Cart = () => {
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: "RIBBED T-SHIRT",
      size: "7-8 years",
      color: "LIGHT PINK",
      price: 15.99,
      quantity: 3,
      new: true,
      imgUrl:
        "https://cdn-media.originalmarines.com/pub/media/catalog/product/cache/69a4eb481d479d64b29a91f2f26f9e2c/D/E/DEA3677F_14-1506TCX_01_D_4.jpg",
    },
    {
      id: 2,
      name: "COTTON T-SHIRT",
      size: "9-10 years",
      color: "DARK GREY",
      price: 15.99,
      quantity: 1,
      new: false,
      imgUrl:
        "https://cdn-media.originalmarines.com/pub/media/catalog/product/cache/69a4eb481d479d64b29a91f2f26f9e2c/D/E/DEA3677F_14-1506TCX_01_D_4.jpg",
    },
  ]);

  const shippingCost = 15.0;
  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const total = subtotal + shippingCost;

  const handleRemoveItem = (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  return (
    <div className="container mx-auto p-4 lg:px-52">
      {/* Cart Items Section */}
      <div className="w-full lg:flex lg:justify-between">
        <div className="w-full lg:w-2/3">
          <h2 className="text-lg font-semibold mb-4">
            Cart ({cartItems.length} ITEMS)
          </h2>

          {/* Labels for Quantity and Total */}
          <div className="hidden lg:flex justify-between font-semibold">
            <div className="flex-1"></div>
            <div className="w-32 text-center">Quantity</div>
            <div className="w-24 text-right">Total</div>
          </div>

          <div className="space-y-4 mt-2">
            {cartItems.map((item) => (
              <div key={item.id} className="border-b pb-4">
                {/* Row Layout for Image and Info */}
                <div className="flex items-center justify-between">
                  {/* Image */}
                  <img
                    className="w-20 h-20 object-cover"
                    src={item.imgUrl}
                    alt={item.name}
                  />

                  {/* Product Info */}
                  <div className="flex-1 ml-4">
                    {item.new && (
                      <span className="text-xs bg-gray-200 text-gray-700 px-2 py-1">
                        NEW-IN
                      </span>
                    )}
                    <h3 className="text-sm font-semibold">{item.name}</h3>
                    <p className="text-sm">Size: {item.size}</p>
                    <p className="text-sm">Color: {item.color}</p>
                  </div>

                  {/* Quantity, Delete, and Total (All in one row) */}
                  <div className="flex items-center justify-between lg:w-48">
                    {/* Quantity Selector */}
                    <select
                      className="border border-gray-300 rounded px-2 py-1"
                      value={item.quantity}
                      onChange={(e) => {
                        const updatedQuantity = parseInt(e.target.value);
                        setCartItems(
                          cartItems.map((ci) =>
                            ci.id === item.id
                              ? { ...ci, quantity: updatedQuantity }
                              : ci
                          )
                        );
                      }}
                    >
                      {[1, 2, 3, 4].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>

                    {/* Delete Button */}
                    <button
                      className="text-red-500 hover:text-red-700 ml-2"
                      onClick={() => handleRemoveItem(item.id)}
                    >
                      <FaTrash />
                    </button>

                    {/* Total */}
                    <p className="font-semibold ml-4">
                      €{(item.price * item.quantity).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Summary Section */}
        <div className="w-full lg:w-1/3 mt-8 lg:mt-0 lg:ml-8 border p-4 rounded-lg bg-gray-50">
          <h3 className="font-semibold mb-4">Cart amount:</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>SUBTOTAL</span>
              <span>€{subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span>SHIPPING COST</span>
              <span>€{shippingCost.toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-semibold">
              <span>ORDER TOTAL</span>
              <span>€{total.toFixed(2)}</span>
            </div>
          </div>

          {/* Proceed to Checkout */}
          <button className="w-full bg-blue-800 text-white py-2 mt-4">
            PROCEED TO CHECKOUT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
