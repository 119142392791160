import React from "react";
import { API_URL_IMAGE_ITEM } from "../api/URL/constants";
import { API_URL_IMAGE_RESIZED } from "../api/URL/constants";
import { Link } from "react-router-dom";

const ProductCard = ({ image, product }) => {
  return (
    <Link to={"/item/" + product.id}>
      <div className="relative border p-2 text-center shadow-sm hover:shadow-lg transition-shadow max-w-full mx-auto cursor-pointer h-full flex flex-col justify-between">
        {/* Discount Badge */}
        {product.discount && (
          <div className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center text-sm font-bold">
            {product.discount}%
          </div>
        )}

        {/* Product Image */}
        <div className="w-full h-60 mb-4 flex justify-center">
          <img
            src={API_URL_IMAGE_RESIZED + product.images[0].name}
            alt={product.name}
            className="object-contain h-full"
          />
        </div>

        {/* Content Centered */}
        <div className="flex flex-col items-center">
          {/* Color Options */}
          <div className="flex items-center justify-center mb-4">
            {product.colors.length > 0 ? (
              product.colors.map((color, index) => (
                <span
                  key={index}
                  className="h-6 w-6 rounded-full border-2 border-white ring-2 mx-1"
                  style={{ backgroundColor: "#" + color.value }}
                ></span>
              ))
            ) : (
              <p className="text-gray-400">No colors available</p>
            )}
          </div>

          {/* Product Name */}
          <h2 className="text-lg font-semibold truncate max-w-full px-2">
            {product.name}
          </h2>

          {/* Product Price */}
          <p className="text-gray-500 font-medium mt-1">
            {product.discount ? (
              <>
                <span className="text-gray-500 font-bold line-through">
                  ${product.price}
                </span>{" "}
                <span className="text-gray-600 font-bold">
                  $
                  {(((100 - product.discount) / 100) * product.price).toFixed(
                    2
                  )}
                </span>
              </>
            ) : (
              <span>${product.price}</span>
            )}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
