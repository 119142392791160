import React, { useState, useEffect } from "react";

const SizeGuideModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("Kids");

  useEffect(() => {
    // Handle back button press
    const handleBackButton = () => {
      if (isOpen) {
        onClose(); // Close modal when back button is pressed
      }
    };

    // Add event listener for the back button
    window.addEventListener("popstate", handleBackButton);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const sizeData = {
    Kids: [
      { cut: "3-4 years", height: 98, chest: 54, waist: 52, hips: 56.5 },
      { cut: "4-5 years", height: 104, chest: 56, waist: 54.5, hips: 60 },
      { cut: "5-6 years", height: 116, chest: 60.5, waist: 56, hips: 64 },
      { cut: "7-8 years", height: 128, chest: 64.5, waist: 58, hips: 70 },
      { cut: "9-10 years", height: 140, chest: 71.5, waist: 61.5, hips: 77 },
      { cut: "11-12 years", height: 152, chest: 78, waist: 63, hips: 86 },
      { cut: "13-14 years", height: 164, chest: 83, waist: 67.5, hips: 88 },
    ],
    Baby: [
      { cut: "3-6 months", height: 68, chest: 45, waist: 45, hips: 45 },
      { cut: "6-9 months", height: 74, chest: 47, waist: 46.5, hips: 48 },
      { cut: "9-12 months", height: 80, chest: 49, waist: 48.5, hips: 50 },
      { cut: "12-18 months", height: 86, chest: 50.5, waist: 50, hips: 52 },
      { cut: "18-24 months", height: 92, chest: 52.5, waist: 50.4, hips: 53 },
      { cut: "30-36 months", height: 98, chest: 54, waist: 52, hips: 56.5 },
    ],
    newborn: [
      {
        cut: "0-1 months",
        height: "50-56 cm",
        chest: 38.8,
        waist: 38.8,
        hips: 37.6,
      },
      { cut: "1-3 months", height: "56-62 cm", chest: 43, waist: 43, hips: 43 },
      { cut: "3-6 months", height: "62-68 cm", chest: 45, waist: 45, hips: 45 },
      { cut: "6-9 months", height: "74 cm", chest: 47, waist: 46.5, hips: 48 },
    ],
    shoes: [
      { cut: "16", length: 10 },
      { cut: "17", length: 11 },
      { cut: "18", length: 11 },
      { cut: "19", length: 11.5 },
      { cut: "20", length: 12.2 },
      { cut: "21", length: 12.8 },
      { cut: "22", length: 13.5 },
      { cut: "23", length: 14.2 },
      { cut: "24", length: 14.8 },
      { cut: "25", length: 15.5 },
      { cut: "26", length: 16.2 },
      { cut: "27", length: 16.8 },
      { cut: "28", length: 17.5 },
      { cut: "29", length: 18.2 },
      { cut: "30", length: 18.8 },
      { cut: "31", length: 19.5 },
      { cut: "32", length: 20.2 },
      { cut: "33", length: 20.9 },
      { cut: "34", length: 21.6 },
      { cut: "35", length: 22.2 },
      { cut: "36", length: 22.9 },
      { cut: "37", length: 23.6 },
    ],
    hats: [
      { cut: "Baby Boy", circumference: "42-47 cm", age: "9-12 months" },
      { cut: "Baby Boy", circumference: "48-51 cm", age: "24-36 months" },
      { cut: "Baby Girl", circumference: "42-46 cm", age: "9-12 months" },
      { cut: "Baby Girl", circumference: "47-48 cm", age: "24-36 months" },
      { cut: "Boy/Girl", circumference: "51-54 cm", age: "3-7 years" },
      { cut: "Boy/Girl", circumference: "55-58 cm", age: "8-14 years" },
    ],
    socks: [
      { cut: "Baby Boy", foot: "15/18", age: "0-9 months" },
      { cut: "Baby Boy", foot: "19/21", age: "12-18 months" },
      { cut: "Baby Boy", foot: "22/24", age: "24-36 months" },
      { cut: "Baby Girl", foot: "15/18", age: "0-9 months" },
      { cut: "Baby Girl", foot: "19/21", age: "12-18 months" },
      { cut: "Baby Girl", foot: "22/24", age: "24-36 months" },
      { cut: "Boy", foot: "23/27", age: "2-4 years" },
      { cut: "Boy", foot: "28/30", age: "5-6 years" },
      { cut: "Boy", foot: "31/33", age: "6-8 years" },
      { cut: "Boy", foot: "34/36", age: "8-10 years" },
      { cut: "Boy", foot: "37/41", age: "12-14 years" },
      { cut: "Girl", foot: "23/27", age: "2-4 years" },
      { cut: "Girl", foot: "28/30", age: "5-6 years" },
      { cut: "Girl", foot: "31/33", age: "6-8 years" },
      { cut: "Girl", foot: "34/36", age: "8-10 years" },
      { cut: "Girl", foot: "37/41", age: "12-14 years" },
    ],
    collant: [
      { cut: "Baby Girl", height: "56-68 cm", age: "0-6 months" },
      { cut: "Baby Girl", height: "68-80 cm", age: "6-12 months" },
      { cut: "Baby Girl", height: "80-86 cm", age: "12-18 months" },
      { cut: "Baby Girl", height: "86-92 cm", age: "18-24 months" },
      { cut: "Girl", height: "93/104 cm", age: "2-4 years" },
      { cut: "Girl", height: "104/116 cm", age: "4-6 years" },
      { cut: "Girl", height: "116/128 cm", age: "6-8 years" },
      { cut: "Girl", height: "128/140 cm", age: "8-10 years" },
      { cut: "Girl", height: "140/152 cm", age: "10-12 years" },
    ],
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getHeaders = () => {
    const headers = {
      Kids: ["Cut", "Height (cm)", "Chest (cm)", "Waist (cm)", "Hips (cm)"],
      Baby: ["Cut", "Height (cm)", "Chest (cm)", "Waist (cm)", "Hips (cm)"],
      shoes: ["Cut", "Length (cm)"],
      hats: ["Cut", "Circumference (cm)", "Age"],
      socks: ["Cut", "Foot Size", "Age"],
      collant: ["Cut", "Height (cm)", "Age"],
    };
    return headers[activeTab] || [];
  };

  const getRowData = (row) => {
    switch (activeTab) {
      case "Kids":
      case "Baby":
        return [row.cut, row.height, row.chest, row.waist, row.hips];
      case "shoes":
        return [row.cut, row.length];
      case "hats":
        return [row.cut, row.circumference, row.age];
      case "socks":
        return [row.cut, row.foot, row.age];
      case "collant":
        return [row.cut, row.height, row.age];
      default:
        return [];
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-11/12 md:w-9/12 lg:w-1/2">
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-semibold">Size Guide</h2>
          <button className="text-xl font-semibold" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="mb-4">
          <ul className="flex space-x-4">
            {["Kids", "Baby", "shoes", "hats", "socks", "collant"].map(
              (tab) => (
                <li
                  key={tab}
                  onClick={() => handleTabClick(tab)}
                  className={`cursor-pointer ${
                    activeTab === tab ? "text-red-500" : ""
                  }`}
                >
                  {tab}
                </li>
              )
            )}
          </ul>
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full text-sm text-center">
            <thead>
              <tr>
                {getHeaders().map((header, index) => (
                  <th key={index} className="px-4 py-2 border-b">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sizeData[activeTab]?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {getRowData(row).map((data, cellIndex) => (
                    <td key={cellIndex} className="px-4 py-2 border-b">
                      {data}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SizeGuideModal;
