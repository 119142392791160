export const CarouselData = [
  {
    type: "image",
    src: "https://originalbucket.originalmarines.com/Grafiche_sito/2025/13gennaio/Sale_eng_NET_Desktop_full_fino70_fw24_13012025.jpg",
  },
  {
    type: "video",
    src: "https://cdn-media.originalmarines.com/pub/media/video/FW24_Slider_Desktop_05112024.mp4",
  },
];
