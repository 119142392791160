import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const StoreLocatorMap = () => {
  // Custom pin icon configuration
  const customIcon = L.divIcon({
    className: "", // Leave it empty to customize with Tailwind
    html: `
      <div class="w-12 h-12 rounded-full bg-white border-4 border-blue-700 flex items-center justify-center shadow-lg">
        <img
          src="https://cdn-media.originalmarines.com/pub/media/favicon/default/mlogo_2.ico"
          alt="Pin Icon"
          class="w-10 h-10 rounded-full"
        />
      </div>
    `,
    iconSize: [48, 48], // Adjust icon size (matches the container)
    iconAnchor: [24, 24], // Center the icon anchor
    popupAnchor: [0, -24], // Popup anchor
  });

  // Store data with name, position, and direction URL
  const stores = [
    {
      name: "Furnshebbak",
      position: [33.8719, 35.5241],
      direction: "https://www.google.com/maps/dir//33.8719,35.5241",
    },
    {
      name: "Marelias",
      position: [33.8772, 35.4886],
      direction:
        "https://www.google.fr/maps/place/Mar+Elias+refugee+camp,+Beirut,+Lebanon/@33.8739397,35.4841842,16z",
    },
    {
      name: "Sour",
      position: [33.2705, 35.2038],
      direction:
        "https://www.google.fr/maps/place/Tyre,+Lebanon/@33.2674152,35.1996239,15z",
    },
    {
      name: "Nabatieh",
      position: [33.3772, 35.4836],
      direction:
        "https://www.google.fr/maps/place/Nabatieh,+Lebanon/@33.3796607,35.4776228,15z",
    },
    {
      name: "Haret Hreik",
      position: [33.8544, 35.5048],
      direction:
        "https://www.google.com/maps/place/original+marines+outlet/@33.8543801,35.503823,18.33z",
    },
    {
      name: "Centro Mall",
      position: [33.8605, 35.4901],
      direction:
        "https://www.google.fr/maps/place/Centro+Mall+Lebanon/@33.8605104,35.4710314,15z",
    },
  ];

  return (
    <div className="flex flex-col sticky lg:flex-row">
      {/* Sidebar */}
      <div
        className="lg:w-1/4 bg-white p-4 overflow-y-auto"
        style={{ maxHeight: "100vh" }}
      >
        <h2 className="text-lg font-bold mb-4">All Our Stores in Lebanon</h2>
        {stores.map((store, index) => (
          <div key={index} className="mb-4 border-b pb-2">
            <h3 className="font-semibold">{store.name}</h3>
            <p>
              <a
                href={store.direction}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                Direction to reach us
              </a>
            </p>
          </div>
        ))}
      </div>

      {/* Map */}
      <div className="lg:w-3/4 h-96 lg:h-screen">
        <MapContainer
          center={[33.8547, 35.8623]} // Centered on Lebanon
          zoom={8} // Adjusted zoom level for better focus on Lebanon
          style={{ height: "100%", width: "100%" }}
          maxBounds={[
            [32.0, 34.0],
            [34.8, 37.0],
          ]} // Boundaries for Lebanon
          maxBoundsViscosity={1.0} // Prevents panning outside Lebanon
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {stores.map((store, index) => (
            <Marker key={index} position={store.position} icon={customIcon}>
              <Popup>
                <strong>{store.name}</strong>
                <br />
                <a
                  href={store.direction}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Direction to reach us
                </a>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default StoreLocatorMap;
