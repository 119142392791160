import React from "react";
import Carousel from "../components/carousel";
import Tabs from "../components/tabs";
import BestSellers from "../components/bestSellers";
import SectionWithCTA from "../components/homeimagesection";
import natalizio from "../assets/natalizio.jpg";
import goodnight from "../assets/goodnight.jpg";

const buttons = [
  [
    {
      name: "Baby Boy",
      route:
        "/items/filter?page=1&category_ids[]=5359b765-325b-43f7-a0a4-2dd74605c196&theme_ids[]=3ad56678-5f3c-45fd-a265-98a5d25ba3bc&theme_ids[]=bba3f9d3-600c-4746-aa28-6b3c96097586",
    },
    {
      name: "Baby Girl",
      route:
        "/items/filter?page=1&category_ids[]=06117cbc-9275-4560-af19-d64de896a2be&theme_ids[]=3ad56678-5f3c-45fd-a265-98a5d25ba3bc&theme_ids[]=bba3f9d3-600c-4746-aa28-6b3c96097586",
    },
  ],
  [
    {
      name: "Boy",
      route:
        "/items/filter?page=1&category_ids[]=fa3e033d-2f79-4cc6-bd8d-a60e2e58b234&theme_ids[]=3ad56678-5f3c-45fd-a265-98a5d25ba3bc&theme_ids[]=bba3f9d3-600c-4746-aa28-6b3c96097586",
    },
    {
      name: "Girl",
      route:
        "/items/filter?page=1&category_ids[]=1c39e8e3-72fa-4e9e-b012-9d1c43648849&theme_ids[]=3ad56678-5f3c-45fd-a265-98a5d25ba3bc&theme_ids[]=bba3f9d3-600c-4746-aa28-6b3c96097586",
    },
  ],
];

const buttons1 = [
  [
    {
      name: "Baby Boy",
      route:
        "/items/filter?page=1&category_ids[]=5359b765-325b-43f7-a0a4-2dd74605c196&theme_ids[]=cc10fa66-9f31-4b86-a376-833c8c8fbe37",
    },
    {
      name: "Baby Girl",
      route:
        "/items/filter?page=1&category_ids[]=06117cbc-9275-4560-af19-d64de896a2be&theme_ids[]=cc10fa66-9f31-4b86-a376-833c8c8fbe37",
    },
  ],
  [
    {
      name: "Boy",
      route:
        "/items/filter?page=1&category_ids[]=fa3e033d-2f79-4cc6-bd8d-a60e2e58b234&theme_ids[]=cc10fa66-9f31-4b86-a376-833c8c8fbe37",
    },
    {
      name: "Girl",
      route:
        "/items/filter?page=1&category_ids[]=1c39e8e3-72fa-4e9e-b012-9d1c43648849&theme_ids[]=cc10fa66-9f31-4b86-a376-833c8c8fbe37",
    },
  ],
];
const Home = () => {
  return (
    <div>
      <Carousel />
      <Tabs />
      {/* <BestSellers products={products} /> */}
      <SectionWithCTA imageSrc={natalizio} buttons={buttons} />
      <SectionWithCTA imageSrc={goodnight} buttons={buttons1} />{" "}
    </div>
  );
};

export default Home;
