import { useContext, useState, useEffect } from "react";
import { sumItems } from "./shoppingCart/ShoppingCartReducer";
import ShoppingCartProductContext from "./shoppingCart/ShoppingCartProductContext";
import { statesData } from "../data/UtilsAddress";
const CustomerDetailsForm = (props) => {
  const { cartItems } = useContext(ShoppingCartProductContext);
  const { itemCount } = sumItems(cartItems);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessageErrorMessage] =
    useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    email: "",
    phone_number: "",
    city: "",
    state: "",
    address: "",
    country_id: "1e8b02c5-c5fc-4049-aa6c-6547431288f0",
    items: null,
  });
  const ClearInputData = () => {
    setFormData({
      firstname: "",
      lastname: "",
      address: "",
      email: "",
      phone_number: "",
      city: "",
      state: "",
      address: "",
      country_id: "1e8b02c5-c5fc-4049-aa6c-6547431288f0",
      items: null,
    });
  };

  useEffect(() => {
    setFormData({ ...formData, state: statesData.regions[0].value });
  }, []);

  function isValidLebaneseNumber(str) {
    const regex = /^(03|76|70|71|78|79|81)\d{6}$/;
    return regex.test(str);
  }
  function containsOnlyLetters(str) {
    const regex = /^[a-zA-Z\s]{3,30}$/;
    return regex.test(str);
  }
  function isValidEmail(str) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(str);
  }
  function isValidAddress(str) {
    if (str !== undefined && str !== null && str.length > 3) {
      return true;
    }
    return false;
  }
  const validateForm = () => {
    setPhoneNumberErrorMessageErrorMessage("");
    setEmailErrorMessage("");
    setFirstNameErrorMessage("");
    setLastNameErrorMessage("");
    setCityErrorMessage("");
    setAddressErrorMessage("");

    if (!containsOnlyLetters(formData.firstname)) {
      setFirstNameErrorMessage(" Please provide a valid first name");
      return;
    }

    if (!containsOnlyLetters(formData.lastname)) {
      setLastNameErrorMessage(" Please provide a valid last name");
      return;
    }

    if (!isValidEmail(formData.email)) {
      setEmailErrorMessage(" Please provide a valid email address");
      return;
    }

    if (!isValidLebaneseNumber(formData.phone_number)) {
      setPhoneNumberErrorMessageErrorMessage(
        " Please provide a valid phone number"
      );
      return;
    }
    if (!isValidAddress(formData.city)) {
      setCityErrorMessage(" Please provide a valid city");
      return;
    }
    if (!isValidAddress(formData.address)) {
      setAddressErrorMessage(" Please provide a valid address");
      return;
    }
    ClearInputData();
    props.scrollTo();
    props.loading(true);
    props.FormCustomerDetails(OrganiseData());
    console.log(formData);
    console.log(OrganiseData());
  };

  const OrganiseData = () => {
    const customerDetails = {
      shipping_customer_details: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phone_number: formData.phone_number,
        state: formData.state,
        city: formData.city,
        address: formData.address,
        country_id: formData.country_id,
      },
      is_customer_details_same: true,
    };
    return customerDetails;
  };

  return (
    <div className="w-full lg:w-1/3 mt-8 lg:mt-0 border p-4 rounded-lg bg-gray-50">
      <h3 className="font-semibold mb-4">Shipping Details</h3>

      <form className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="email" className="block text-sm font-medium">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            autoComplete="email"
            className="w-full border border-gray-300 rounded px-2 py-2"
            placeholder="Your email"
          />
          <span className="text-red-600 text-xs ">{emailErrorMessage}</span>
        </div>

        <div className="flex space-x-4">
          <div className="w-1/2">
            <label htmlFor="firstName" className="block text-sm font-medium">
              First Name
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              onChange={(e) =>
                setFormData({ ...formData, firstname: e.target.value })
              }
              className="w-full border border-gray-300 rounded px-2 py-2"
              placeholder="First name"
            />
            <span className="text-red-600 text-xs ">
              {firstNameErrorMessage}
            </span>
          </div>
          <div className="w-1/2">
            <label htmlFor="lastName" className="block text-sm font-medium">
              Last Name
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              onChange={(e) =>
                setFormData({ ...formData, lastname: e.target.value })
              }
              className="w-full border border-gray-300 rounded px-2 py-2"
              placeholder="Last name"
            />
            <span className="text-red-600 text-xs ">
              {lastNameErrorMessage}
            </span>
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="phone" className="block text-sm font-medium">
            Phone Number
          </label>
          <input
            type="tel"
            name="phone_number"
            id="phone_number"
            onChange={(e) =>
              setFormData({ ...formData, phone_number: e.target.value })
            }
            className="w-full border border-gray-300 rounded px-2 py-2"
            placeholder="Phone number"
          />
          <span className="text-red-600 text-xs ">
            {phoneNumberErrorMessage}
          </span>
        </div>

        <div className="space-y-2">
          <label htmlFor="country" className="block text-sm font-medium">
            Country
          </label>
          <input
            type="text"
            id="country"
            className="w-full border border-gray-300 rounded px-2 py-2"
            value="Lebanon"
            readOnly
          />
        </div>

        <div className="space-y-2">
          <label htmlFor="state" className="block text-sm font-medium">
            State
          </label>
          <select
            id="state"
            name="state"
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:max-w-xs sm:text-sm sm:leading-6"
          >
            {statesData.regions.map((region) => (
              <option key={region.value}>{region.label}</option>
            ))}
          </select>
        </div>

        <div className="space-y-2">
          <label htmlFor="city" className="block text-sm font-medium">
            City
          </label>
          <input
            type="text"
            name="city"
            id="city"
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            className="w-full border border-gray-300 rounded px-2 py-2"
            placeholder="City"
          />
          <span className="text-red-600 text-xs ">{cityErrorMessage}</span>
        </div>

        <div className="space-y-2">
          <label htmlFor="address" className="block text-sm font-medium">
            Street Address
          </label>
          <input
            type="text"
            name="address"
            id="address"
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
            className="w-full border border-gray-300 rounded px-2 py-2"
            placeholder="Street Address"
          />
          <span className="text-red-600 text-xs ">{addressErrorMessage}</span>
        </div>
        {itemCount >= 1 ? (
          <div
            onClick={validateForm}
            className="w-full bg-blue-800 text-white py-2 mt-4 text-center rounded-sm cursor-pointer"
          >
            CHECKOUT
          </div>
        ) : (
          <button disabled className="w-full bg-gray-400 text-white py-2 mt-4">
            CHECKOUT
          </button>
        )}
      </form>
    </div>
  );
};
export default CustomerDetailsForm;
