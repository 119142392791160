import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ItemFiltredAPI = {
  getAll: async function (page, params = {}, cancel = false) {
    let url = `api/items/filter?per_page=12&page=${page}`;

    if (params.categoryId.length > 0) {
      url += "&";
      let categoryIdParams = new URLSearchParams();
      params.categoryId.forEach((id) => {
        categoryIdParams.append("category_ids[]", id);
      });
      url += categoryIdParams.toString();
    }
    if (params.typeId.length > 0) {
      url += "&";
      let typeIdParams = new URLSearchParams();
      params.typeId.forEach((id) => {
        typeIdParams.append("type_ids[]", id);
      });
      url += typeIdParams.toString();
    }
    if (params.themeId.length > 0) {
      url += "&";
      let themeIdParams = new URLSearchParams();
      params.themeId.forEach((id) => {
        themeIdParams.append("theme_ids[]", id);
      });
      url += themeIdParams.toString();
    }
    if (params.sizeId.length > 0) {
      url += "&";
      let sizeIdParams = new URLSearchParams();
      params.sizeId.forEach((id) => {
        sizeIdParams.append("size_ids[]", id);
      });
      url += sizeIdParams.toString();
    }
    if (params.colorId.length > 0) {
      url += "&";
      let colorIdParams = new URLSearchParams();
      params.colorId.forEach((id) => {
        colorIdParams.append("color_ids[]", id);
      });
      url += colorIdParams.toString();
    }
    if (params.discountId.length > 0) {
      url += "&";
      let discountIdParams = new URLSearchParams();
      params.discountId.forEach((id) => {
        discountIdParams.append("discount_ids[]", id);
      });
      url += discountIdParams.toString();
    }
    // if (
    //   params.collectionId !== undefined &&
    //   params.collectionId !== null &&
    //   params.collectionId !== "1"
    // ) {
    //   url += `&collection_id=${params.collectionId}`;
    // }
    if (params.search !== undefined && params.search !== null) {
      url += `&search=${params.search}`;
    }
    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getMultipleTypesItems: async function (page, ids, cancel = false) {
    let url = `api/items/multiple/types?per_page=12&page=${page}&ids=${ids}`;
    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(ItemFiltredAPI);
