import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import promo30 from "../assets/promo30.png";
import promo50 from "../assets/promo50.png";
import promo70 from "../assets/promo70.png";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Kids",
      range: "3-14 YEARS",
      content: [
        {
          image:
            "https://cdn-media.originalmarines.com/pub/media/catalog/category/REPARTI_BA_fw24_21082024.jpg",
          title: "Girl",
          route:
            "/items/filter?page=1&category_ids[]=1c39e8e3-72fa-4e9e-b012-9d1c43648849",
        },
        {
          image:
            "https://cdn-media.originalmarines.com/pub/media/catalog/category/REPARTI_BO_fw24_21082024.jpg",
          title: "Boy",
          route:
            "/items/filter?page=1&category_ids[]=fa3e033d-2f79-4cc6-bd8d-a60e2e58b234",
        },
      ],
    },
    {
      title: "Baby",
      range: "3-36 MONTHS",
      content: [
        {
          image:
            "https://cdn-media.originalmarines.com/pub/media/catalog/category/REPARTI_NA_fw24_21082024.jpg",
          title: "Baby Girl",
          route:
            "/items/filter?page=1&category_ids[]=06117cbc-9275-4560-af19-d64de896a2be",
        },
        {
          image:
            "https://cdn-media.originalmarines.com/pub/media/catalog/category/REPARTI_NO_fw24_21082024.jpg",
          title: "Baby Boy",
          route:
            "/items/filter?page=1&category_ids[]=5359b765-325b-43f7-a0a4-2dd74605c196",
        },
        {
          image:
            "https://cdn-media.originalmarines.com/pub/media/catalog/category/Reparti_Mobile_Newborn_10112022.jpg",
          title: "New Born",
          route:
            "/items/filter?page=1&category_ids[]=04a7a03d-a11a-424f-b48b-c0ac5b6f9093",
        },
      ],
    },
    {
      title: "Promo",
      range: "0-14 YEARS",
      content: [
        {
          image: promo30,
          title: "Exclusive Promo Deals",
          description:
            "Don’t miss out on these exclusive promo deals for parents!",
          route:
            "/items/filter?page=1&discount_ids[]=01c5a56d-d205-4967-9e55-bd392b9d43c0",
        },
        {
          image: promo50,
          title: "Limited-Time Offers",
          description:
            "Check out these limited-time offers for your little ones.",
          route:
            "/items/filter?page=1&discount_ids[]=eb68d119-7070-4fa8-9fdd-79cd4d003e2d",
        },
        {
          image: promo70,
          title: "Limited-Time Offers",
          description:
            "Check out these limited-time offers for your little ones.",
          route:
            "/items/filter?page=1&discount_ids[]=c28e8299-49c3-4887-a40d-6f7ddada339c",
        },
      ],
    },
  ];

  return (
    <div className="w-full max-w-screen-lg mx-auto mt-10 mb-6">
      <h2 className="text-center text-2xl mb-8 mt-8 text-blue-900 font-bold lg:text-gray-500">
        News and Trends
      </h2>

      <div className="flex justify-center space-x-6 mt-8">
        {tabs.map((tab, index) => (
          <div key={index} className="text-center">
            <button
              onClick={() => setActiveTab(index)}
              className={`px-6 py-3 text-lg font-semibold border text-gray-500 border-gray-200 focus:outline-none transition-all duration-300 ease-in-out transform ${
                activeTab === index
                  ? "text-red-600 border-red-600 scale-110"
                  : "hover:text-red-500 hover:scale-105"
              } ${index !== 0 ? "-ml-px" : ""} whitespace-nowrap`}
              role="tab"
              aria-selected={activeTab === index}
            >
              {tab.title}
            </button>
            <span
              className={`block text-sm mt-2 ${
                activeTab === index ? "text-red-600" : "text-gray-500"
              }`}
            >
              {tab.range}
            </span>
          </div>
        ))}
      </div>

      {/* Carousel for mobile and tablet */}
      <div className="mt-8 sm:hidden">
        <Swiper
          spaceBetween={16}
          slidesPerView={1.5}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
        >
          {tabs[activeTab].content.map((card, index) => (
            <SwiperSlide key={index}>
              <div className="group relative">
                <Link to={card.route}>
                  <div className="relative h-[calc(100%+3rem)] mx-2 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75">
                    <img
                      alt={card.title}
                      src={card.image}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                </Link>
                <h3 className="mt-6 text-sm text-red-500 text-center font-bold">
                  <Link to={card.route}>{card.title}</Link>
                </h3>
                {card.description && (
                  <p className="text-base font-semibold text-gray-900 hidden">
                    {card.description}
                  </p>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Grid layout for larger screens */}
      <div
        className={`hidden mb-36 sm:grid mt-8 gap-6 ${
          tabs[activeTab].content.length === 2
            ? "sm:grid-cols-2 place-items-center"
            : tabs[activeTab].content.length === 3
            ? "sm:grid-cols-3 place-items-center"
            : "sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4"
        }`}
      >
        {tabs[activeTab].content.map((card, index) => (
          <div key={index} className="group relative">
            <Link to={card.route}>
              <div className="relative h-[calc(100%+3rem)] w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75">
                <img
                  alt={card.title}
                  src={card.image}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </Link>
            <h3 className="mt-6 text-sm text-gray-500 text-center font-bold ">
              <Link to={card.route}>{card.title}</Link>
            </h3>
            {card.description && (
              <p className="text-base font-semibold text-gray-900 sm:hidden">
                {card.description}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
