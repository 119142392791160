import React, { createContext, useState, useEffect } from "react";
import { NavBarAPI } from "../../api/NavBarAPI";
export const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [navbarData, setNavbarData] = useState([]);

  const orderList = [
    { id: "1c39e8e3-72fa-4e9e-b012-9d1c43648849", order: 0 },
    { id: "fa3e033d-2f79-4cc6-bd8d-a60e2e58b234", order: 1 },
    { id: "06117cbc-9275-4560-af19-d64de896a2be", order: 2 },
    { id: "5359b765-325b-43f7-a0a4-2dd74605c196", order: 3 },
    { id: "04a7a03d-a11a-424f-b48b-c0ac5b6f9093", order: 4 },
  ];

  const sortNavBarByid = (mainList, orderList) => {
    // here we remove the ACCESSORIES category
    const filteredList = mainList.filter(
      (item) => item.id !== "ac5fe7fa-64b6-456c-b1eb-532c6df69995"
    );

    const orderMap = new Map(orderList.map((item) => [item.id, item.order]));

    return [...filteredList].sort((a, b) => {
      const orderA = orderMap.get(a.id);
      const orderB = orderMap.get(b.id);

      if (orderA === undefined && orderB === undefined) return 0;
      if (orderA === undefined) return 1;
      if (orderB === undefined) return -1;

      return orderA - orderB;
    });
  };

  const fetchData = async () => {
    if (navbarData.length === 0) {
      try {
        NavBarAPI.get().then((data) => {
          const sortedList = sortNavBarByid(data, orderList);
          setNavbarData(sortedList);
        });
      } catch (error) {
        console.error("Error in navbar data", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <NavbarContext.Provider value={{ navbarData, setNavbarData }}>
      {children}
    </NavbarContext.Provider>
  );
};
