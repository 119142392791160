import React, { useState, useContext, useEffect } from "react";
import { FaFilter } from "react-icons/fa";
import { filterData } from "../data/filters";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbarContext } from "./navbar/NavbarContext";
const AccordionFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const { navbarData } = useContext(NavbarContext);
  const getSizeIdsFromUrl = () => {
    const params = new URLSearchParams(location.search);
    const sizeIds = params.getAll("size_ids[]");
    return sizeIds;
  };
  const getColorIdsFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.getAll("color_ids[]");
  };
  const getTypeIdsFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.getAll("type_ids[]");
  };
  const getDiscountIdsFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.getAll("discount_ids[]");
  };
  const getTypesByCategoryId = () => {
    const params = new URLSearchParams(location.search);
    const categoryId = params.getAll("category_ids[]");
    const obj = navbarData.find((item) => item.id === categoryId[0]);
    return obj ? obj.types : undefined;
  };
  const [selectedSizes, setSelectedSizes] = useState(getSizeIdsFromUrl());
  const [selectedColors, setSelectedColors] = useState(getColorIdsFromUrl());
  const [selectedTypes, setSelectedTypes] = useState(getTypeIdsFromUrl());
  const [selectedDiscounts, setSelectedDiscounts] = useState(
    getDiscountIdsFromUrl()
  );
  const handleFilterToggle = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const selectSize = (size) => {
    const params = new URLSearchParams(location.search);
    let updatedSizes;
    if (selectedSizes.includes(size)) {
      updatedSizes = selectedSizes.filter((s) => s !== size);
    } else {
      updatedSizes = [...selectedSizes, size];
    }
    setSelectedSizes(updatedSizes);
    params.delete("size_ids[]");
    updatedSizes.forEach((id) => params.append("size_ids[]", id));
    navigate({ search: params.toString() });
  };

  const selectColor = (color) => {
    const params = new URLSearchParams(location.search);
    let updatedColors;
    if (selectedColors.includes(color)) {
      updatedColors = selectedColors.filter((s) => s !== color);
    } else {
      updatedColors = [...selectedColors, color];
    }
    setSelectedColors(updatedColors);
    params.delete("color_ids[]");
    updatedColors.forEach((id) => params.append("color_ids[]", id));
    navigate({ search: params.toString() });
  };

  const selectType = (type) => {
    const params = new URLSearchParams(location.search);
    let updatedTypes;
    if (selectedTypes.includes(type)) {
      updatedTypes = selectedTypes.filter((s) => s !== type);
    } else {
      updatedTypes = [...selectedTypes, type];
    }
    setSelectedTypes(updatedTypes);
    params.delete("type_ids[]");
    updatedTypes.forEach((id) => params.append("type_ids[]", id));
    navigate({ search: params.toString() });
  };

  const selectDiscount = (discount) => {
    const params = new URLSearchParams(location.search);
    let updatedDiscount;
    if (selectedDiscounts.includes(discount)) {
      updatedDiscount = selectedDiscounts.filter((s) => s !== discount);
    } else {
      updatedDiscount = [...selectedDiscounts, discount];
    }
    setSelectedDiscounts(updatedDiscount);
    params.delete("discount_ids[]");
    updatedDiscount.forEach((id) => params.append("discount_ids[]", id));
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    const getTypes = getTypesByCategoryId();
    if (getTypes != undefined) {
      setTypes(getTypesByCategoryId());
    }
  }, [navbarData]);

  return (
    <div className="relative">
      {/* Mobile Filter Toggle */}
      <div className="flex justify-between items-center mt-9 md:hidden">
        <button
          className="bg-blue-600 text-white p-2 rounded-full"
          onClick={handleFilterToggle}
        >
          <FaFilter size={20} />
        </button>
      </div>

      {/* Filter Sidebar */}
      <div
        className={`${
          isFilterOpen
            ? "block fixed top-0 left-0 w-full h-full bg-gray-100 z-20 p-4 overflow-y-auto"
            : "hidden"
        } md:block lg:sticky lg:top-0 lg:h-screen lg:overflow-y-auto p-4 pt-28 mt-5 border-r`}
      >
        {/* Mobile Close Button */}
        <div className="flex justify-between items-center mb-4 md:hidden">
          <h2 className="text-xl font-semibold">Filters</h2>
          <button
            className="text-red-500 font-bold"
            onClick={handleFilterToggle}
          >
            Close
          </button>
        </div>

        {/* Filter Options */}
        <div className="space-y-5">
          {types.length > 0 ? (
            <div>
              <h3 className="text-md font-semibold py-2">Type</h3>
              <div className="grid grid-cols-2 gap-2 pl-3 mt-2">
                {types.map((type, index) => (
                  <label key={index} className="flex items-center text-sm">
                    <input
                      type="checkbox"
                      className="form-checkbox mr-2"
                      checked={
                        selectedTypes.length > 0
                          ? selectedTypes.includes(type.id)
                          : true
                      }
                      onChange={() => selectType(type.id)}
                    />
                    {type.name}
                  </label>
                ))}
              </div>
            </div>
          ) : null}
          <div>
            <h3 className="text-md font-semibold py-2">Size</h3>
            <div className="grid grid-cols-3 gap-2 pl-3 mt-2">
              {filterData.sizes.map((size, index) => (
                <div
                  key={size.id}
                  className={`border rounded-md px-2 py-1 text-xs hover:bg-gray-200 text-center cursor-pointer ${
                    selectedSizes.includes(size.id)
                      ? "border-blue-600 border-2"
                      : "border"
                  }`}
                  onClick={() => selectSize(size.id)}
                >
                  {size.name}
                </div>
              ))}
            </div>
          </div>

          {/* Color Filter */}
          <div>
            <h3 className="text-md font-semibold py-2">Color</h3>
            <div className="grid grid-cols-6 gap-2 pl-5 mt-2">
              {filterData.colors.map((color, index) => (
                <span
                  key={color.id}
                  className={`h-5 w-5 rounded-full  border-gray-300 cursor-pointer ${
                    selectedColors.includes(color.id)
                      ? "border-blue-600  ring "
                      : "border"
                  }`}
                  style={{ backgroundColor: "#" + color.value }}
                  onClick={() => selectColor(color.id)}
                ></span>
              ))}
            </div>
          </div>

          {/* Price Filter */}
          {/* <div>
            <h3 className="text-md font-semibold py-2">Price</h3>
            <div className="pl-3 mt-2">
              <div className="flex justify-between text-sm">
                <span>Min</span>
                <span>Max</span>
              </div>
              <input
                type="range"
                min="0"
                max="1000"
                step="10"
                className="w-full mt-2"
              />
              <div className="flex justify-between text-xs mt-1">
                <span>$0</span>
                <span>$1000</span>
              </div>
            </div>
          </div> */}

          {/* Discount Filter */}
          <div>
            <h3 className="text-md font-semibold py-2">Discount</h3>
            <div className="grid grid-cols-3 gap-2 pl-3 mt-2">
              {filterData.discounts.map((discount, index) => (
                <div
                  key={index}
                  // className="border rounded-md px-2 py-1 text-xs hover:bg-gray-200"
                  className={`border rounded-md px-2 py-1 text-xs hover:bg-gray-200 text-center cursor-pointer ${
                    selectedDiscounts.includes(discount.id)
                      ? "border-blue-600 border-2"
                      : "border"
                  }`}
                  onClick={() => selectDiscount(discount.id)}
                >
                  {discount.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionFilter;
