import {
    REMOVE_ITEM,
    ADD_TO_CART,
    INCREASE,
    DECREASE,
    CHECKOUT,
    CLEAR,
  } from "./ShoppingCartTypes";
  import ReactPixel from "react-facebook-pixel";
  export const sumItems = (cartItems) => {
    let itemCount = cartItems.reduce(
      (total, product) => total + product.quantity,
      0
    );
       let total = cartItems.reduce((total, product) => {       
        if (product.discount && product.discount !== null) {
          total += (product.price * (1 - product.discount / 100)) * product.quantity;
        } else {
          total += product.price * product.quantity;
        }
        return total;
      }, 0).toFixed(2);
    return { itemCount, total };
  };

  const saveCartToLocalStorage = (cartItems) => {
    localStorage.setItem('cartItem', JSON.stringify(cartItems));
  };
  const ShoppingCartReducer = (state, action) => {
 
    switch (action.type) {
      case ADD_TO_CART:
        if (!state.cartItems.find((item) => item.id === action.payload.id)) {
          state.cartItems.push({
            ...action.payload,
            quantity: 1,
          });
        }
        ReactPixel.track("AddToCart",{
          content_ids: action.payload.id,
          content_name: action.payload.name,
          value: action.payload.price,
          currency: "$"
        })
         saveCartToLocalStorage([...state.cartItems]);
        return {
          ...state,
          ...sumItems(state.cartItems),
          cartItems: [...state.cartItems],
        };
  
      case REMOVE_ITEM:
        const updateCart = [
          ...state.cartItems.filter((item) => item.id !== action.payload.id),
        ];
        saveCartToLocalStorage(updateCart);
        return {
          ...state,
          ...sumItems(
            state.cartItems.filter((item) => item.id !== action.payload.id)
          ),
          cartItems: updateCart,
        };
  
      case INCREASE:
        state.cartItems[
          state.cartItems.findIndex((item) => item.id === action.payload.id)
        ].quantity++;
        saveCartToLocalStorage([...state.cartItems]);
        return {
          ...state,
          ...sumItems(state.cartItems),
          cartItems: [...state.cartItems],
        };
  
      case DECREASE:
        state.cartItems[
          state.cartItems.findIndex((item) => item.id === action.payload.id)
        ].quantity--;
        saveCartToLocalStorage([...state.cartItems]);
        return {
          ...state,
          ...sumItems(state.cartItems),
          cartItems: [...state.cartItems],
        };
  
      case CHECKOUT:
        return {
          cartItems: [],
          checkout: true,
          ...sumItems([]),
        };
  
      case CLEAR:
        saveCartToLocalStorage([]);
        return {
          cartItems: [],
          ...sumItems([]),
        };
  
      default:
        return state;
    }
  };
  export default ShoppingCartReducer;