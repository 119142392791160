import React from "react";

const Exchange = () => {
  return (
    <div className="bg-white min-h-screen py-10 px-4 md:px-10 lg:px-20">
      <h1 className="text-2xl text-red-500 md:text-4xl font-bold text-center mb-8">
        Exchange Policies
      </h1>
      <div className="space-y-8">
        {/* Policies Section */}
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl text-blue-900 font-semibold mb-4">
            Exchange Policies
          </h2>
          <p className="text-gray-700">
            Dear Original customers, we take great pride in our products and
            hope that you will enjoy shopping on our website. In case you are
            not satisfied with your purchase, you may exchange it by following
            the below conditions:
          </p>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
            <li>
              Through our online system, you can only change the size or color
              of your purchased items.
            </li>
            <li>
              Items must have original tags attached to them and remain unworn,
              unwashed, and in good condition.
            </li>
            <li>Items with Sale over 70% OFF may not be exchanged.</li>
            <li>
              Hygienic items like briefs, tights, and swimwear cannot be
              exchanged.
            </li>
            <li>Offline orders are not eligible for exchanges online.</li>
          </ul>
        </section>

        {/* Conditions Section */}
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl text-blue-900 font-semibold mb-4">
            Exchange Conditions
          </h2>
          <p className="text-gray-700">
            Please read exchange conditions before requesting an exchange:
          </p>
          <ul className="list-disc list-inside mt-4 space-y-2 text-gray-700">
            <li>
              Exchange requests should be appealed within 3 days of delivery.
            </li>
            <li>
              All exchanges are at your expense except for the following cases:
              <ul className="list-inside list-disc mt-2 ml-4">
                <li>We accidentally sent you the wrong merchandise.</li>
                <li>You received damaged merchandise.</li>
              </ul>
            </li>
            <li>
              Initial shipping fees and return shipping costs are not
              refundable.
            </li>
          </ul>
        </section>

        {/* How to Exchange Items Section */}
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl text-blue-900 font-semibold mb-4">
            How to Exchange Items
          </h2>
          <div className="space-y-4 text-gray-700">
            <div>
              <h3 className="font-bold">At our website:</h3>
              <p>
                Check our conditions and policies to exchange items, log into
                your account at{" "}
                <a
                  href="https://www.originalmarinesoutlets.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  www.originalmarinesoutlets.com
                </a>
                , and follow the steps. Exchanges may take up to 5 business days
                to be processed.
              </p>
            </div>
            <div>
              <h3 className="font-bold">At our shops:</h3>
              <p>
                Visit an Original Marines outlet store with your receipt, and
                hand over the original to receive the new one.
              </p>
            </div>
          </div>
        </section>

        {/* Refunds Section */}
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl text-blue-900 font-semibold mb-4">Refunds</h2>
          <p className="text-gray-700">
            Please note that no refunds on orders are available, no exceptions.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Exchange;
